@import "../../scss/mixin.scss";

.nav-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-blue;
    position: relative;
    z-index: 2;
    width: 100%;
    .nav-bar {
        display: flex;
        position: fixed;
        max-width: 100vw;
        background-color: $light-blue;
        top: 0px;
        width: 100%;
        justify-content: center;
        padding: 1rem 1rem;
        @include tablet {
            padding: 0.75rem 1rem;
        }
        @include mobile {
            padding: 0.5rem 1rem;
            img {
                width: 125px;
                
            }
        }
    }
    .nav-bar.opacity {
        background-color: rgba($light-blue, 0.3);
    }
}

.links {
    width: fit-content;
}

.burger-line {
    margin-bottom: 5px;
    height: 2px;
    background-color: $beige;
    color: $beige;
    content: " ";
    width: 25px;
    @include mobile {
        width: 20px;
    }
}
.burger-line:last-child {
    margin-bottom: 0;
}

.openbtn {
    z-index: 2;
    cursor: pointer;
}

.burger-menu {
    background-color: $beige;
    background-size: 360% 360%;
    opacity: 1;

    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include tablet {
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
    }
    @include mobile {
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }
}

.links {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 5rem;

    @include tablet {
        padding-left: 0;
        justify-content: center;
        align-items: center;
    }
    @include mobile {
        padding-left: 0;
        justify-content: center;
        align-items: center;
    }
    h6 {
        margin-bottom: 0.5em;
    }
}

.logo {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    padding-right: 5rem;
    opacity: 1;
    @include tablet {
        padding: 4rem 0 6rem 0;
        justify-content: center;
        width: 100%;
    }
    @include mobile {
        padding: 4rem 0 6rem 0;
        justify-content: center;
        width: 100%;
    }
}
