@import "../../scss/mixin.scss";

.half-half {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(to right, $light-blue 50%, $beige 50%);
}
.half {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(to left, $light-blue 50%, $beige 50%);
}
.floorplan {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $beige;
  padding-bottom: 5rem;
  .contain {
    max-width: min(100%, 1400px);
  }

  .selectors {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    .selector {
      padding: 1.5rem 0;
      display: flex;
      justify-content: center;
      width: 50%;
      cursor: pointer;
      h4 {
        text-transform: uppercase;
      }
      background-color: $light-blue;
    }

    .selector.active {
      background-color: $beige;
      h4 {
        color: black;
      }
    }
  }
  .flex-wrapper {
    display: flex;
    height: 100%;
    @include mobile {
      flex-direction: column;
    }
    .floorplan-table {
        padding: 3rem 0 0 0;
        @include mobile {
          padding: 0;
        }
        @include tablet {
            padding: 3rem 0 0 0;
        }
    }
    .text-half {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile {
        display: none;
      }
      .hr {
        color: $light-blue;
        margin: 1.5rem 0;
        background-color: $light-blue;
        width: 200px;
        height: 2px;
      }
      h2 {
        color: $light-blue;
      }
      h4 {
        padding: 2px 0;
      }
    }
    .floorplan-title{
        padding: 5rem 0 0 0;
    }
    .btns {
      padding: 0 0 5rem 0;
      @include small-desktop{ 
        padding: 0 0 5rem 0;
      }
      @include mobile {
        padding: 0;
      }
      @include tablet {
        padding: 0;
      }
    }
    .floorplan-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 25px;
      }
      h4 {
        color: $light-blue;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 1em;
        cursor: pointer;
        @include mobile {
          padding: 10px 5px;
        }
      }
      h4:hover {
        text-decoration: underline;
      }
    }
    .floorplan-btn.inactive {
      h4 {
        padding-left: calc(1em + 25px);

        font-weight: 400;
        @include mobile {
          padding-left: 5px;
        }
      }
    }
  }
  .half-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem 1.5rem;
    width: 50%;
    position: relative;
    @include mobile {
      padding: 3rem 1.5rem;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      .mobile {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: 1.5rem;
      }
    }
    img {
      max-width: 100%;
      @include mobile {
        width: 100%;
      }
    }

    .key {
      position: absolute;
      bottom: 0;
      left: 1.5rem;
      @include mobile {
        position: absolute;
        left: 1.5rem;
      }
      .key-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
          padding-right: 20px;
        }
      }
      img {
        padding-bottom: 0.5rem;
        width: auto;
        height: 2em;
      }
      h4 {
        color: $light-blue;
        font-size: 11px;
      }
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $light-blue;
    padding: 5px 0;
    .cell {
      display: flex;
      justify-content: flex-end;
    }
  }
  .row:nth-child(9) {
    .cell {
      h5 {
        font-weight: bold;
      }
    }
  }
}

.mobile {
  display: none;
  @include mobile {
    display: flex;
  }
  .floorplan-btn {
    padding-top: 1.5rem;
    opacity: 1;
  }
  .floorplan-btn.inactive {
    opacity: 0.4;
  }
}


.floorplan-img {

  min-height: 100%;
  height: 100%;
}


.white-text{
  h4 {
    font-size: 20px;
    @include tablet {
      font-size: 16px;
    }
  }
}