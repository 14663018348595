@import "../../scss/mixin.scss";
@import "../../scss/global.scss";
.beige-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 0 0 6rem 450px;

  @include small-desktop {
    padding-left: 400px;
  }
  @include tablet {
    padding-left: 0;
    padding-bottom: 3rem;
  }
  @include mobile {
    padding-bottom: 3rem;
    padding-left: 0;
  }
  .box {
    width: calc(100% - 3rem);
    padding: 3rem;
    @include mobile {
      padding: 1.5rem;
      width: calc(100% - 1.5rem);
    }
    h5 {
      max-width: 50%;
      @include mobile {
        b {
          font-size: 20px;
        }
        max-width: 100%;
        font-size: 16px;
        color: white;
      }
      @include tablet {
        max-width: 100%;
        font-size: 20px;
        color: white;
      }
    }
    h5.header {
      font-size: 20px;
      padding-bottom: 1rem;
    }
  }
}

.gallery-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.photo-wrapper {
  justify-content: flex-end;
  width: fit-content;
  display: flex;
  padding: 0 3rem 0 0;
  max-width: 90%;

  @include large-desktop {
    justify-content: center;
    width: 100%;
  }

  @include small-desktop {
    .left-wrapper {
      .flex-box {
        margin-bottom: -1.5rem;
        img {
          height: 80%;
        }
      }
      div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  @include small-desktop {
    .left-wrapper {
      .flex-box {
        margin-bottom: -1.5rem;
        img {
          height: 80%;
        }
      }
    }
  }
  img {
    max-width: 100%;
    @include small-desktop {
      width: max-content;
    }
  }

  .left-wrapper {
    display: flex;
    margin-left: -3rem;
    margin-top: -3rem;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 1rem 0 0;
    .img-pad {
      padding: 1.2rem 0;
    }

    .flex-box {
      display: flex;
      justify-content: flex-end;
      .img-pad {
        padding: 0 1rem 0 0;
        @include small-desktop {
          padding: 0 2rem 0 0;
        }
      }
    }
  }
}

.beige {
  background-color: $beige;
  @include tablet {
    background-color: $light-blue;
  }
  @include mobile {
    background-color: $light-blue;
  }
}

.img-pad {
  padding-bottom: 1rem;
}

.right-wrapper {
  img {
    display: block;
  }
  margin-top: -3rem;
  padding-bottom: 3rem;
}

.loc-lower-split {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  h1 {
    padding-left: 450px;

    @include small-desktop {
      padding-left: 400px;
    }
    @include tablet {
      padding-left: 300px;
    }
    @include mobile {
      padding-left: 100px;
    }
  }
}

.map {
  position: relative;
  padding: 3rem 0;
  width: 100%;
  display: flex;
  @include tablet {
    flex-direction: column;
  }
  @include mobile {
    flex-direction: column;
    padding: 1.5rem 0;
  }
  .map-image {
    width: 55%;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      width: 100%;
    }
  }

  h2 {
    padding: 0 0 1.5rem 0;
    @include small-desktop {
      font-size: 30px;
    }
  }

  .amen {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 3rem;
    width: 45%;
    background-color: $beige;

    .row {
      display: flex;
      justify-content: flex-start;
      h5:first-child {
        width: 25px;
      }
    }

    @include small-desktop {
      .row {
        padding: 2px 0;
      }
      h5 {
        font-size: 12px;
      }
    }
    @include tablet {
      padding: 1.5rem 3rem;
      width: 100%;

      h2 {
        display: none;
      }
    }
    @include mobile {
      padding: 1.5rem 1.5rem;
      width: 100%;

      h2 {
        display: none;
      }
    }
  }
}

.split-table {
  display: flex;
  .table {
    b {
      padding-right: 1.5rem;
    }
  }
  .table:first-child {
    padding-right: 3rem;
    @include tablet {
      padding-right: 1.5rem;
    }
    @include mobile {
      padding-right: 1.5rem;
    }
  }
}
.travel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include tablet {
    padding: 1rem 0;
  }
  @include mobile {
    padding: 1rem 0;
  }

  img {
    padding: 0.5rem 0;
  }
  h3 {
    padding: 1rem 0;
  }
}
.logo-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .img-wrapper {
    width: 20px;
    display: flex;
    justify-content: center;
  }
  h4 {
    padding: 0.5rem;
  }
}

.travel-section {
  display: flex;
  width: 100%;
  padding: 1.5rem 0 5rem 0;
  @include tablet {
    flex-direction: column-reverse;
    padding: 0 3rem;
  }
  @include mobile {
    flex-direction: column-reverse;
    padding: 0 1.5rem;
  }
  .travel-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 55%;
    @include tablet {
      width: 100%;
      flex-direction: column;
      padding: 3rem 0;
    }
    @include mobile {
      width: 100%;
      flex-direction: column;
      padding: 1.5rem 0;
    }
  }

  .travel-logo {
    img {
      width: 75px;
    }
    h3 {
      max-width: 100px;
      font-size: 20px;
    }
    @include tablet {
      display: flex;
      align-items: center;
      h3 {
        padding-left: 20px;
      }
      img {
        width: 50px;
      }
    }
    @include mobile {
      display: flex;
      align-items: center;
      h3 {
        padding-left: 20px;
      }
      img {
        width: 50px;
      }
    }
  }

  .ug-lines {
    @include tablet {
      display: flex;
    }
    @include mobile {
      display: flex;
    }
    .logo-text {
      @include tablet {
        padding: 8px;
      }
      @include mobile {
        padding: 8px;
      }
    }
  }

  .travel-text {
    width: 45%;
    display: flex;
    flex-direction: column;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      width: 100%;
    }
    h3 {
      padding: 1rem 0;
    }
    h4 {
      width: 45%;
      @include tablet {
        width: 60%;
      }
      @include mobile {
        width: 100%;
      }
    }
  }
}

.top-images {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to top, $beige 40%, $light-blue 40%);
  div {
    width: 49.5%;
    margin-top: auto;
  }
  img {
    max-width: 100%;
  }
  .second-image {
    max-width: 100%;
  }
}

.loc-mobile {
  display: none;
  @include tablet {
    display: block;
  }
  @include mobile {
    display: block;
  }
  .beige-section {
    background-color: $beige;
    img {
      width: 100%;
      padding-top: 5px;
    }
    h5 {
      padding: 1.5rem;
      @include tablet {
        padding: 3rem;
      }
    }
  }
}
.bottom-images {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 25px;
  div {
    width: 49.5%;
    margin-bottom: auto;
  }
  img {
    max-width: 100%;
  }
  .second-image {
    max-width: 100%;
  }
  img.triangle {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scale(-1);
    display: block;
    width: 50%;
    padding: 0;
  }
}

h2.mobile {
  padding: 0 1.5rem 20px 1.5rem;
  font-size: 20px;
}

.desktop {
  @include tablet {
    display: none;
  }
  @include mobile {
    display: none;
  }
}

.text-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  h5 {
    padding-top: 3rem;
    max-width: 500px;
    b {
      font-size: 20px;
    }
    @include small-desktop {
      padding-top: 1.5rem;
    }
  }
}

.location-swiper {
  padding: 0 0rem;
  background-image: linear-gradient(to bottom, $beige 50%, $light-blue 50%);
  @include tablet {
    padding: 0;
  }
  @include mobile {
    padding: 0;
  }
}

.blue {
  background-color: $light-blue;
}


.blue-box{
  h5{
    color: white;
    font-size: 16px;
    b{
      font-size: 20px;
    }
  }
}

.text-wrap{
  max-width: min(100%, $max-width);
  padding: 1.5rem 3rem;
  @include mobile {
    padding: 1.5rem 1.5rem 10rem 1.5rem;
    background-color: $beige;
  }
  h5{
    color: white;
    font-size: 16px;
    width: 60%;
    @include tablet {
      width: 80%;
    }
    @include mobile {
      width: 100%;
      color: black;
    }
    b{
      font-size: 20px;
    }
  }
}

.triangle-loc {
  display: none;
  @include mobile {
    position: absolute;
    display: block;
    bottom: 0;
    right: 0;
    transform: scale(-1);
  }
}

.mixed{
  background: linear-gradient(to bottom, $beige 50%, $light-blue 50%);

}