@import "../../scss/mixin.scss";

.bar-hero {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8c8b2;
    .footer-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #e8c8b2;
        bottom: 0;
        width: 100%;
        padding: 1rem 3rem;
        @include mobile {
            padding: 0.75rem 1.5rem;
        }
        .download {
            h4 {
                text-transform: uppercase;
                font-size: 16px;
                color: rgba($color: #000000, $alpha: 0.54);
            }
        }
        .download:hover {
            text-decoration: underline;
        }
    }
}

.siren{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    a{
        color: $beige;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}
