$beige : #E8C8B2;
$light-blue: #86A1B2;
$white: #F4F4F4;
$back-gradient: rgba(0, 0, 0, 0.7);

@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}


@mixin padding{
    padding: 0 3rem;

    @include mobile{
        padding: 0 1.5rem;
    }
}