@import '../../scss/mixin.scss';

.avail {
    padding-top: 51.2px;
    @include mobile {
        padding-top: 31.2px;
    }
    @include tablet {
        padding-top: 31.2px;
    }
}

.avail-split{
    
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    @include padding;
    padding-left: 450px;

    @include small-desktop {
        padding-left: 300px;
    }
    @include tablet {
        padding-left: 250px;
    }
    @include mobile {
       padding-left: 0;

       h1 {
        padding-left: 170px;
       }
    }

    .table-box {
        @include padding;
        padding-top: 3rem;
        padding-bottom: 3rem;
        display: flex;
        justify-content: space-between;
        @include large-desktop {
           justify-content: flex-start;
        }
        @include mobile {
            padding-top: 1.5rem;
            padding-bottom: 3rem;
            flex-direction: column-reverse;
        }
        .table {
            display: flex;
            flex-direction: column;
            width: 40%;
            @include mobile {
                width: 100%;
            }
            .row {
                display: flex;
                border-bottom: 2px solid $beige;
                width: 100%;
                justify-content: space-between;
                .cell{
                    display: flex;
                    justify-content: flex-end;
                    width: 33%;
                    color: white;
                    font-family: "europa";
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 400; // Make the text bold
                    padding: 10px 0;
                }
                .cell:first-child{
                    justify-content: flex-start;
                }
            }

            .row:last-child {
                border-bottom: none;
                .cell {
                    font-weight: bold;
                }
            }

        }
        h4{
            width: 48%;
            @include large-desktop {
                width: 40%;
                padding-left: 10rem;
            }
            @include mobile {
                width: 100%;
                padding-bottom: 1.5rem;
            }
        }
    }
}

.pad-floor-swiper{
    padding: 1.5rem 3rem 3rem 3rem;
    background-color: $light-blue;
    position: relative;
    @include mobile {
        padding: 0;
    }
}