@import "../../scss/mixin.scss";

.spec{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 3rem 3rem 6rem 3rem;

    @include tablet {
        padding: 3rem 1.5rem 6rem 1.5rem;
    }

    @include mobile {
        padding: 3rem 0 6rem 0;
    }
    .spec-icon{
        display: flex;
        width: 25%;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 3rem 1.5rem;
        @include small-desktop {
            padding: 3rem 1.5rem;
            width: 33%;
        }
        @include tablet {
            padding: 1.5rem 1.5rem;
            width: 33%;
        }
        @include mobile {
            padding: 1.5rem 0.5rem 1.5rem 0;
            width: 50%;
        }

        text-align: center;
        h4{
            max-width: 80%;
            @include tablet{
                max-width: 800%;
            }
            @include mobile{
                max-width: 80%;
            }
        }
        img {
            padding-bottom: 1em;
            z-index: -1;
            position: relative;
            height: 100px;
            @include mobile {
                height: 80px;
            }
        }
    }
}