@import "../../scss/mixin.scss";

.contact {
  position: relative;
    padding: 40px 0 0 0;
    h1 {
        padding: 100px 0 0 3rem;
        line-height: 1em;
        font-size: 80px;
    }
    .offset {
        h1 {
            padding: 0 0 0 150px;
        }
    }
    .mobile {
        @include mobile {
            display: flex;
            justify-content: flex-start;
            h1 {
                padding: 1.5rem 1.5rem;
                font-size: 30px;
                font-weight: bold;
            }
        }
        @include tablet {
            display: flex;
            justify-content: flex-start;
            h1 {
                padding: 1.5rem 1.5rem;
                font-size: 30px;
                font-weight: bold;
            }
        }
    }
}
.center-align {
    max-width: 1400px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column  ;
}

.start{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.center {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.agents-wrapper-center{
  display: flex;
  justify-content: center;
  width: 100%;
}

.agents-wrapper {
    width: fit-content;
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 5rem 200px 5rem 4rem;
    max-width: 1400px;

    img{
        padding: 3rem 0 1.5rem 0;
    }

    @include mobile {
        flex-direction: column;
        padding: 0 1.5rem;
        img {
            padding: 1rem 0;
        }
    }
    @include tablet {
        flex-direction: column;
        padding: 0 1.5rem;
        img {
            padding: 1rem 0;
        }
    }
    .agents {
        width: fit-content;
        padding: 1.5rem 0 0 0;
        @include mobile {
            padding: 1rem 0;
        }
        @include tablet {
            padding: 1rem 0;
        }
        .agent {
            padding: 1.5rem rem 0 0;
            width: fit-content;
        }
    }
    p {
        color: $beige;
        font-family: "europa";
        font-weight: 400;
        padding: 2px 0;
    }
}

.misrep {
    max-width: 1400px;
    padding: 0rem 3rem 0 155px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h5 {
        color: $white;
        font-size: 11px;
        padding: 0 1.5rem 50px 0;
    }

    @include mobile {
        padding: 0 1.5rem 0 1.5rem;
    }
    @include tablet {
        padding: 0 1.5rem 0 1.5rem;
    }
}

.desktop {
    @include mobile {
        display: none;
    }
    @include tablet {
        display: none;
    }
}


img.marble {
    z-index: -1;
    @include mobile {
        display: none;
    }
    @include tablet {
        display: none;
    }
}

.company {
    @include mobile{
        padding: 0 0 1.5rem 0;
    }
    @include tablet{
        padding: 0 0 1.5rem 0;
    }
}

.contain.splitter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.mail:hover {
    text-decoration: underline;
    color: $beige;
}