@import "../../scss/mixin.scss";

.mid {
  width: 100%;
  padding: 0 0;
  background: linear-gradient(0, $light-blue 50%, $beige 50%);
  @include mobile {
    background: unset;
  }
  // display: flex;
  z-index: 0;
  position: relative;
  @include mobile {
    padding: 0 0;
  }
  .swiper-wrapper {
    width: 100%;
    .swiper-slide {
      width: 100%;
      img {
        width: 100%;
        display: block;
        padding-bottom: 35px;
        // z-index: -1;
        // position: relative;

        @include mobile {
          padding: 1rem 0 3rem 0;
        }
      }
    }
  }

  .swiper-button-next {
    content: url("../../../public/images/arrow.svg");
    background-color: $light-blue;
    padding: 0 12px;
    width: 50px;
    height: 50px;
    transform: rotate(-90deg);
    right: var(--swiper-navigation-sides-offset, 3rem);
    top: var(--swiper-navigation-top-offset, calc(50% - 27px));

    @include mobile {
      right: 1.5rem;
      top: calc(100% - 1.1rem);
      width: 30px;
      height: 30px;
      padding: 0 7px;
    }
  }
  .swiper-button-prev {
    content: url("../../../public/images/arrow.svg");
    background-color: $light-blue;
    padding: 0 12px;
    width: 50px;
    height: 50px;
    transform: rotate(90deg);
    left: var(--swiper-navigation-sides-offset, 3rem);
    top: var(--swiper-navigation-top-offset, calc(50% - 27px));

    @include mobile {
      left: 1.5rem;
      top: calc(100% - 1.1rem);
      width: 30px;
      height: 30px;
      padding: 0 7px;
    }
  }

  .swiper-pagination-bullet {
    background-color: unset;
    height: 12px;
    width: 12px;
    border-radius: 0;
    border: 2px solid $beige;
    opacity: 1;
    @include mobile {
      border: 2px solid $light-blue;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: $beige;
    @include mobile {
      background-color: $light-blue;
    }
  }

  .swiper-pagination {
    display: flex;
    left: unset;
    z-index: -1;
    span:first-child {
      margin-left: 0;
    }
    span {
      margin: 3px;
    }
    @include mobile {
      display: block;
    }
  }
}

.hero {
  width: 100%;
  z-index: 0;
  position: relative;
  .swiper-button-next {
    content: url("../../../public/images/arrow.svg");
    background-color: $light-blue;
    padding: 0 12px;
    width: 3rem;
    height: 3rem;
    transform: rotate(-90deg);
    right: 0;
    top: var(--swiper-navigation-top-offset, calc(50%));
    @include mobile {
      display: none;
    }
  }
  .swiper-button-prev {
    content: url("../../../public/images/arrow.svg");
    background-color: $light-blue;
    padding: 0 12px;
    width: 3rem;
    height: 3rem;
    transform: rotate(90deg);
    left: 0;
    top: var(--swiper-navigation-top-offset, calc(50%));
    @include mobile {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background-color: unset;
    height: 12px;
    width: 12px;
    border-radius: 0;
    border: 2px solid $beige;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: $beige;
  }

  .swiper-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 1.5rem;

    span:last-child {
      margin-left: 0;
    }
    span {
      margin: 3px;
    }
  }
}

.mid.blank-back {
  background: $light-blue;

  .swiper-pagination {
    display: flex;
    flex-direction: row;
    padding-left: 0;

    span:first-child {
      margin-left: 0;
    }
    span {
      margin: 3px;
    }
  }
  .swiper-button-next {
    right: 3rem;
  }
  .swiper-button-prev {
    left: 3rem;
  }
}

.mid.blue-pag {
  background-color: $beige;
  max-width: calc(100% - 6rem);
  @include tablet {
    max-width: unset;
  }
  @include mobile {
    max-width: unset;
  }
  .swiper-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
    position: relative;
    bottom: 30px;

    span {
      margin: 3px;
    }
  }
  @include mobile {
    .swiper-pagination {
      display: block;
      width: 100%;
      bottom: 35px;
      span:first-child {
        margin-left: 0;
      }
      span {
        margin: 3px;
      }
    }
    .swiper-pagination-bullet {
      background-color: $beige;
      border: 2px solid $light-blue;
    }
    .swiper-pagination-bullet-active {
      background-color: $light-blue;
      border: 2px solid $light-blue;
    }
    .swiper-button-next {
      right: 1.5rem;
      top: calc(100% - 1.1rem);
      width: 30px;
      height: 30px;
      padding: 0 7px;
      display: block;
      z-index: 2;
    }
    .swiper-button-prev {
      left: 1.5rem;
      top: calc(100% - 1.1rem);
      width: 30px;
      height: 30px;
      padding: 0 7px;
      display: block;
    }
  }
}
.mid.beige-pag {
  background-color: $light-blue;
  .swiper-pagination {
    padding: 0;
    left: 0rem;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  @include mobile {
    padding: 0;

    .swiper-pagination {
      display: block;
      left: 1rem;
      span:first-child {
        margin-left: 0;
      }
      span {
        margin: 3px;
      }
    }
    .swiper-pagination-bullet {
      background-color: $light-blue;
      border: 2px solid $beige;
    }
    .swiper-pagination-bullet-active {
      background-color: $beige;
      border: 2px solid $beige;
    }
    .swiper-button-next {
      background-color: $beige;
      right: 1.5rem;
      top: calc(100% - 1.1rem);
      width: 30px;
      height: 30px;
      padding: 0 7px;
      display: block;
      z-index: 2;
    }
    .swiper-button-prev {
      background-color: $beige;
      left: 1.5rem;
      top: calc(100% - 1.1rem);
      width: 30px;
      height: 30px;
      padding: 0 7px;
      display: block;
    }
  }
}

.avail-swip {
  .swiper-slide {
    img {
      width: 100%;
      height: 85vh;
      object-fit: cover;
      @include mobile {
        width: 100%;
        height: auto;
      }
      @include mobile {
        width: 100%;
        height: auto;
      }
    }
  }
}

img.swiper-img-back {
  object-fit: cover;
  height: 85vh;
  display: block;

  @include mobile {
    width: 100vw;
    object-fit: cover;

    height: 100%;
  }
  @include tablet {
    width: 100vw;
    object-fit: cover;

    height: 100%;
  }
}

.mid.blue-pag.loc {
  @include tablet {
    .swiper-button-prev {
      top: calc(100% - 2.5rem);
    }
    .swiper-button-next {
      top: calc(100% - 2.5rem);
    }
  }
  @include mobile {
    .swiper-button-prev {
      top: calc(100% - 2.5rem);
    }
    .swiper-button-next {
      top: calc(100% - 2.5rem);
    }
  }
}
